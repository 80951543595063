import React from "react";

const StyleSheet = ()=>{

    return(
        <div>
            <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                crossOrigin="anonymous"
            />
            {/*<link*/}
            {/*    rel="stylesheet"*/}
            {/*    href="./bootstrap-overrides.css"*/}
            {/*/>*/}
            <link
                rel="stylesheet"
                href="./NavBar/NavBar.css"
            />
        </div>
    );


}

export default StyleSheet;
