import React from 'react';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed'
import "./VideoPlayer.css"


const VideoPlayer = (props)=>{
    const {channel} = props;

    return(
        <div >
            <h1>{channel.user_name}</h1>
            <h1>{channel.title}</h1>

                <iframe
                    src={`https://player.twitch.tv/?channel=${channel.user_name}&parent=www.twitchlowstreams.tv&autoplay=true&muted=false`}
                    height="600"
                    width="1080"

                    frameBorder="0"
                    scrolling="no"
                    muted={"false"}
                    allowFullScreen={"true"}>
                </iframe>

        </div>
    );


}

export default VideoPlayer;
