import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
// import "./NavBar.css"
const NavBar = (props)=>{
    const {games,selectedGame,title,reroll} = props;

    return(
        <div>
            <Navbar bg = "light" variant="light"  expand="lg" >
                <Navbar.Brand id="navBrand" onClick={reroll} >{title}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className= "mr-auto">
                        {/*<Nav.Link href="#home">Home</Nav.Link>*/}
                        <NavDropdown title="Games" id="basic-nav-dropdown">
                            {
                                games.map(game => {
                                    const {name} = game;
                                    return(
                                        <NavDropdown.Item onClick={selectedGame}>{name}</NavDropdown.Item>
                                    );
                                })
                            }
                        </NavDropdown>
                    </Nav>
                    {/*<Form inline>bsPrefix="custom-btn custom-btn-outline"*/}
                    {/*    <FormControl type="text" placeholder="Search" className="mr-sm-2" />*/}
                    {/*    <Button variant="outline-secondary">Search</Button>*/}
                    {/*</Form>*/}
                </Navbar.Collapse>
            </Navbar>
        </div>
    );


}

export default NavBar;
