import React, {Component} from 'react';
// import dotenv from 'dotenv';
import VideoPlayer from './Components/VideoPlayer';
import './App.css';
import axios from 'axios';
import NavBar from "./Components/NavBar/NavBar";
import StyleSheet from "./Components/StyleSheet";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import demo from "./Assets/demo.gif"
import ReactGA from 'react-ga';
import divWithClassName from "react-bootstrap/esm/divWithClassName";

 // if (process.env.NODE_ENV !== 'production') { require('dotenv').config() };
const client_id = process.env.REACT_APP_CLIENT_ID;
// const auth = process.env.REACT_APP_AUTH;
const redirect = process.env.REACT_APP_URL;
let type = 'games/top';
let language = 'en';
const viewerLimit = 20;
let url =  'https://id.twitch.tv/oauth2/authorize?client_id='+client_id+'&redirect_uri=' +redirect + '&response_type=token';
let helix;


class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            games:[],
            game:[],
            stream:[],
            cursor:[],
            token:[]
        }

        ReactGA.initialize('UA-167733549-1');
        ReactGA.pageview(window.location.pathname);
    }

    randomLength = (sett) =>{
        let min = 0;
        let max = sett.length;
        let random = Math.floor(Math.random() * (+max - +min)) + +min;
        return random;
    }

    componentDidMount() {
        let s = document.location.hash

        if(s.length>0){
            const match = s.match(new RegExp("#access_token=(.*)&scope="));

            this.setState({token:{id:match[1]}}, () => {
                helix = axios.create({
                    // baseURL: 'https://id.twitch.tv/oauth2/authorize',
                    baseURL: 'https://api.twitch.tv/helix/',
                    headers: {
                        'Client-ID': client_id,
                        'Authorization': 'Bearer ' + this.state.token.id
                    }
                });
                type = 'games/top';
                //gets top games
                helix.get(`${type}`).then(response =>{
                    this.setState({games: response.data.data});
                }).then(() =>{
                    let random = this.randomLength(this.state.games);
                    //gets random top game
                    this.setState({game: this.state.games[random]})
                }).then(() =>{
                    this.getLastPage();
                })
            })


        }


    }

  //get info
  getGame = (event)=>{
      const games = this.state.games;
      games.find((game)=> {
          if(game.name === event.target.text){
              this.setState({stream: {user_name:null , title:"Finding Streamer"}});

               this.setState({game: game}, ()=>{
                   this.getLastPage();
               });
          }else{
              return null
          }
      });
  }

  //Get streams
  getStreams = ()=>{
      type = 'streams';
    const {id} = this.state.game;
    let cursor = this.state.cursor;
    if(cursor === undefined || cursor.length === 0){
        cursor = '';
    }
    helix.get(type+'?game_id=' + id+'&language=en&first=100&before='+cursor).
    then(response =>{
        let random = this.randomLength(response.data.data);
        this.setState({stream: response.data.data[random]},()=>{
            if(this.state.stream.viewer_count > viewerLimit){
                this.getStreams();
            }
        })
    }).catch(console.log);
  }

 //add function for viewers check for 0 views or lowest count?
  getLastPage = () => {
      type = 'streams';
      let pagins = '';
      let pCount = 0;
      const {id} = this.state.game;
      const req = () =>{
          return helix.get(`${type}?game_id=${id}&language=en&first=100&after=${pagins}`)
              .then((response)=>{
                  if(response.data.pagination.cursor !== undefined){
                      pCount++;
                      pagins =  response.data.pagination.cursor;

                  }
                  if(response.data.pagination.cursor === undefined || pCount === 10 ){
                      if(pCount<= 1){
                          pagins = '';
                      }

                      this.setState({cursor:pagins} , () => {
                          this.getStreams();
                      })
                      return pagins;
                  }
                  return req();
              }).catch(console.log);

      }
      return  req();

  }
  //add search for specific streamer

  render(){
      let videoPlayer = null;
      let info = null;
      let log = null;
      let navBar = null;
      let welcome= null;
      if(this.state.stream.length !== 0){
          info = null;
          videoPlayer =
              <div>
                  <div className={"player"}>
                      <VideoPlayer channel={this.state.stream}/>
                  </div>
                  {/*<div className={"next"}>*/}
                  <br/>
                  <div >
                      <a onClick={this.getStreams} className="next">Discover Another Streamer</a>
                  </div>
              </div>

      }

      if(this.state.token.length < 1){
          welcome=
              <div>
                  <br/>
                  <h2>Welcome to TwitchLowStreams</h2>
                  <img src={demo} className="img-fluid" alt=""/>
                  <h3>Please login to Twitch to begin discovering new streamers</h3>
                  <br/>
              </div>


          navBar =
          <Navbar bg = "light" variant="light"  expand="lg" >
              <Navbar.Brand  href="#">{this.props.appTitle}</Navbar.Brand>
          </Navbar>
          // ur= window.location.href;
          log = <Button variant="outline-secondary" onClick={()=>window.location.href=`${url}`}>Login</Button>

      }else{
          navBar = <NavBar games = {this.state.games} selectedGame = {this.getGame} title = {this.props.appTitle} reroll={this.getStreams} />
          if(this.state.stream.length === 0 ){
              info = <h1>Finding Streamer</h1>
          }
      }
    return (
        <div className="App">
            {navBar}
            <div id ="content-wrap">
                {welcome}
                {log}
                {info}
                {videoPlayer}
                <StyleSheet/>
            </div>
            <footer id = "footer">TLS 2019-2020</footer>
        </div>
    );
  }
}

export default App;
